import { atom } from 'recoil'

export const configState = atom({
  key: 'configState',
  default: {
    WEB_APP_NAME: '',
    DOMAIN_NAME: '',
    CLIENT_ID: '',
    STRESS_PROXY_URL: '',
    INTEX_PROXY_URL: ''
  }
})
