const initialState = {
  usernameData: null  
}

const usernameDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_USERNAME_DATA': {
      return { 
        ...state, 
        usernameData:action.payload
      }
    }
    default : {
      return state
    }
  }
}
   
export default usernameDataReducer 