// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import stageDataReducer from './stageData'
import usernameDataReducer from './userData'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  stageDataReducer,
  usernameDataReducer
})

export default rootReducer
