import { useEffect, useState, lazy } from 'react'
import { config as localConfig } from '../../../configs/config'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from 'authConfig'
import { useRecoilState } from 'recoil'
import { configState } from 'state/atoms'
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'

const LazyApp = lazy(() => import('App'))

export const RoutesManager: React.FC = () => {
  const [config, setConfig] = useRecoilState(configState)
  const [instance, setInstance] = useState<IPublicClientApplication | null>(null)

  const getConfig = async () => {
    const config = process.env.NODE_ENV === 'development' ? localConfig : (window as any).config
    setConfig(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    if (config?.WEB_APP_NAME) {
      const isDevEnv = process.env.NODE_ENV === 'development'
      const msalInstance = new PublicClientApplication({
        ...msalConfig,
        auth: {
          ...msalConfig.auth,
          clientId: isDevEnv ? msalConfig.auth.clientId : config.CLIENT_ID,
          redirectUri: isDevEnv
            ? msalConfig.auth.redirectUri
            : `https://${config.WEB_APP_NAME}.${config.DOMAIN_NAME}`
        }
      })

      setInstance(msalInstance)
    }
  }, [config])

  return (
    instance && (
      <MsalProvider instance={instance}>
        <LazyApp />
      </MsalProvider>
    )
  )
}
