export const msalConfig = {
  auth: {
    clientId: '46238d2f-e71c-4fa9-bf69-4277b9e9aa0f',
    authority: 'https://login.microsoftonline.com/aa42167d-6f8d-45ce-b655-d245ef97da66', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: 'http://localhost:3000'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: true
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
// export const graphConfig = {
//   graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
// }
