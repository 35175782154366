const initialState = {
  stageData: null
}

const stageDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_STAGE_DATA': {
      return { 
        ...state, 
        stageData:action.payload
      }
    }
    case 'UNLOAD_STAGE_DATA': {
      return {
        ...state,
        stageData: null
      }
    }
    default : {
      return state
    }
  }
}
   
export default stageDataReducer 